import { useMemo } from 'react';

import { useGetConstant } from 'src/hooks/useHandleSessions';

function useViewChoice(moduleName, choiceType, value) {
  const constantValues = JSON.parse(useGetConstant());
  // Define the module data inside the hook
  // const modules = {
  //   'v1.operations': {
  //     WithdrawalTypeChoice: { BANK: 111, CASH: 222 },
  //     TransferCurrencyChoice: { AED: 111, USD: 222 },
  //     ClientTransferRateTypeChoice: { CURRENCY: 111, AMOUNT: 222 },
  //     StatusChoices: {
  //       PENDING: 111,
  //       DEPOSITED: 222,
  //       TRANSFERRED: 333,
  //       RECEIVED: 444,
  //       WITHDRAWN: 555,
  //       COMPLETED: 666,
  //     },
  //   },
  //   'v2.user': {
  //     schoolTypeChoice: { BANK: 111, CASH: 222 },
  //     userCurrencyChoice: { AED: 111, USD: 222 },
  //     userTransferRateTypeChoice: { CURRENCY: 111, AMOUNT: 222 },
  //     UserChoices: {
  //       PENDING: 111,
  //       DEPOSITED: 222,
  //       TRANSFERRED: 333,
  //       RECEIVED: 444,
  //       WITHDRAWN: 555,
  //       COMPLETED: 666,
  //     },
  //   },
  // };
  const modules = {
    'v1.operations': constantValues['v1.operations'],
  };

  return useMemo(() => {
    const module = modules[moduleName];
    if (!module || !module[choiceType]) {
      return { options: [] };
    }

    const options = Object.entries(module[choiceType]).map(([label, val]) => ({
      label,
      value: val,
    }));

    let currentLabel = null;
    if (value !== undefined) {
      currentLabel =
        Object.entries(module[choiceType]).find(([, val]) => val === value)?.[0] || null;
    }

    return value !== undefined ? { options, currentLabel } : { options };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName, choiceType, value]);
}

export default useViewChoice;
