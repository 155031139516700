import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, del, post } from './http';
import { activeSnack } from '../store/common';

export const getAllDeposits = createAsyncThunk(
  'deposits/list',
  async ({
    page,
    search,
    dispatch,
    bankDepositCard,
    client,
    combanyBankAccount,
    dateFrom,
    dateTo,
    limit,
    depositType,
  }) => {
    try {
      const response = await get(
        `/operations/deposit/?page=${page ?? 1}&client_search=${search ?? ''}&page_size=${
          limit || 10
        }&client=${client ?? ''}&bank_deposit_card=${bankDepositCard ?? ''}&company_bank_account=${
          combanyBankAccount ?? ''
        }&date_from=${dateFrom ?? ''}&date_to=${dateTo ?? ''}&deposit_type=${depositType || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createDeposit = createAsyncThunk(
  'deposit/create',
  async ({ state, dispatch, navigate }) => {
    try {
      const URL = `/operations/deposit/`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.deposit);
        }
        dispatch(activeSnack({ type: 'success', message: 'Deposit Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteDeposit = createAsyncThunk(
  'deposit/delete',
  async ({ depositId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/operations/deposit/${depositId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(activeSnack({ type: 'success', message: 'Deposit Deleted Successfully' }));
        return depositId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
