import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { alpha } from '@mui/system';
// import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Tab,
  Tabs,
  Stack,
  useTheme,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllClients } from 'src/server/api/client';
import { getAllCompanyBanks } from 'src/server/api/companyBank';
import { getAllBankDepositCards } from 'src/server/api/bankDepositCard';

import Iconify from 'src/components/iconify';

export default function DepositTableToolbar({ filterData }) {
  const dispatch = useDispatch();
  // const filtersVisible = useBoolean();
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');

  const {
    selectedClient,
    setSelectedClient,
    selectedBankDepositCard,
    setSelectedBankDepositCard,
    selectedCompanyBankAccount,
    setSelectedCompanyBankAccount,
    searchText,
    handleSearch,
    dateRange,
    handleDateChange,
    handlesetPage,
    updateURLParams,
    sort,
    setSort,
  } = filterData;

  const { clients, depositCards, companyBanks, constants } = useSelector((state) => ({
    loading: state.client.loading,
    clients: state.client.clients,
    depositCards: state.bankDepositCard.depositCards,
    companyBanks: state.companyBank.companyBanks,
    constants: state.settings.constants['v1.operations'],
  }));

  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextDepositCard, setSearchTextDepositCard] = useState('');
  const [searchTextCompanyBank, setSearchTextCompanyBank] = useState('');

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextClient || '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllClients(credentials));
  }, [dispatch, searchTextClient]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextDepositCard || '',
      limit: 0,
      dispatch,
    };
    dispatch(getAllBankDepositCards(credentials));
  }, [dispatch, searchTextDepositCard]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextCompanyBank || '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllCompanyBanks(credentials));
  }, [dispatch, searchTextCompanyBank]);

  const optionClients = [
    { label: 'All', value: '' },
    ...(clients?.results || []).map((result) => ({
      label: result?.name,
      value: result?.id,
    })),
  ];

  const OptionBankDepositCard = [
    { label: 'All', value: '' },
    ...(depositCards?.results || []).map((result) => ({
      label: result?.title,
      value: result?.id,
    })),
  ];

  const OptionCompanyBankAccount = [
    { label: 'All', value: '' },
    ...(companyBanks?.results || []).map((result) => ({
      label: result?.title,
      value: result?.id,
    })),
  ];

  const handleClientAutocompleteChange = (e, newValue) => {
    setSelectedClient({ label: newValue?.label || '', value: newValue?.value || '' });
    handlesetPage();
    updateURLParams('client', { label: newValue?.label, value: newValue?.value });
  };

  const handleSearchClient = (e) => {
    setSearchTextClient(e.target.value);
  };

  const handlesetBankDepositCardsAutocompleteChange = (e, newValue) => {
    setSelectedBankDepositCard({ label: newValue?.label || '', value: newValue?.value || '' });
    handlesetPage();
    updateURLParams('bankDepositCard', { label: newValue?.label, value: newValue?.value });
  };

  const handleSearchDepositCard = (e) => {
    setSearchTextDepositCard(e.target.value);
  };

  const handlesetCompanyBankAccountAutocompleteChange = (e, newValue) => {
    setSelectedCompanyBankAccount({ label: newValue?.label || '', value: newValue?.value || '' });
    handlesetPage();
    updateURLParams('companyBankAccount', { label: newValue?.label, value: newValue?.value });
  };

  const handleSearchCompanyBank = (e) => {
    setSearchTextCompanyBank(e.target.value);
  };

  // const handleClose = () => {
  //   filtersVisible.onFalse();
  // };

  const statusOptions = Object.entries(constants?.DepositTypeChoices || {}).map(
    ([label, value]) => ({
      label,
      value,
    })
  );

  const TABS = [{ value: '', label: 'All' }, ...statusOptions];

  return (
    <>
      <Stack
        px={2}
        direction={smDown ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Tabs
          value={sort}
          onChange={(e, value) => {
            setSort(value);
          }}
          sx={{
            ml: 0,
            px: 2.5,
            boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            width: smDown ? 'unset' : '50%',
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label
                .toLowerCase()
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
              iconPosition="end"
              // icon={
              //   <Label variant={tab.value === sort ? 'filled' : 'soft'} color={tab.color}>
              //     {tab.count}
              //   </Label>
              // }
            />
            // <TabComponent tab={tab} sort={sort} />
          ))}
        </Tabs>
        <TextField
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          size="small"
          placeholder="Search client"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
          sx={{ width: smDown ? '100%' : '50%' }}
        />
      </Stack>
      <Box
        p={2}
        rowGap={3}
        columnGap={2}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(4, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
      >
        {/* <TextField
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          size="small"
          placeholder="Search client"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
          sx={{ width: '100%' }}
        /> */}
        <Autocomplete
          id="client-select-demo"
          defaultValue={selectedClient}
          options={optionClients}
          autoHighlight
          size="small"
          getOptionLabel={(option) => option.label || ''}
          onChange={handleClientAutocompleteChange}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              onChange={handleSearchClient}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <Autocomplete
          id="bank-deposit-card-select-demo"
          defaultValue={selectedBankDepositCard}
          options={OptionBankDepositCard}
          autoHighlight
          size="small"
          getOptionLabel={(option) => option.label || ''}
          onChange={handlesetBankDepositCardsAutocompleteChange}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bank Deposit Card"
              onChange={handleSearchDepositCard}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <Autocomplete
          id="company-bank-account-select-demo"
          defaultValue={selectedCompanyBankAccount}
          options={OptionCompanyBankAccount}
          autoHighlight
          size="small"
          getOptionLabel={(option) => option.label || ''}
          onChange={handlesetCompanyBankAccountAutocompleteChange}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company Bank Account"
              onChange={handleSearchCompanyBank}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            name="from-date"
            label="Start Date"
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={dateRange.from}
            onChange={(e) => handleDateChange('from', e.target.value)}
          />
          <TextField
            name="to-date"
            label="End Date"
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={dateRange.to}
            onChange={(e) => handleDateChange('to', e.target.value)}
          />
        </Stack>
        {/* <Box gridColumn={{ xs: 'span 1', sm: 'span 5', md: 'span 1' }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="end"
            width="100%"
            position="relative"
          >
            <Typography variant="body2" sx={{ fontWeight: 700, color: '#212B36' }}>
              Filter by Date
            </Typography>
            <IconButton
              onClick={() => filtersVisible.onToggle()}
              size="large"
              sx={{
                color: filtersVisible.value ? 'black' : '#212B36',
                fontWeight: filtersVisible.value ? 'bold' : 'normal',
              }}
            >
              <FilterListIcon />
            </IconButton>
            {filtersVisible.value && (
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    gap: 1,
                    position: 'absolute',
                    top: 43,
                    zIndex: 1,
                    right: 0,
                    minWidth: 400,
                  }}
                >
                  <TextField
                    name="from-date"
                    label="Start Date"
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={dateRange.from}
                    onChange={(e) => handleDateChange('from', e.target.value)}
                  />
                  <TextField
                    name="to-date"
                    label="End Date"
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={dateRange.to}
                    onChange={(e) => handleDateChange('to', e.target.value)}
                  />
                </Card>
              </ClickAwayListener>
            )}
          </Stack>
        </Box> */}
      </Box>
    </>
  );
}

DepositTableToolbar.propTypes = {
  filterData: PropTypes.any,
};
