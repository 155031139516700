import React from 'react';
// import { useLocation } from 'react-router';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

// import TrasferPage from './trasferPage';

function AllTrasfers() {
  const settings = useSettingsContext();
  // const location = useLocation();

  // const getValueFromSearch = (searchString, parameterName) => {
  //   const searchParams = new URLSearchParams(searchString);
  //   return searchParams.get(parameterName);
  // };

  // const searchValue = getValueFromSearch(location.search, 'search');

  return (
    <>
      <MetaHelmet title="Trasfer" />
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        Transfer Page
        {/* {searchValue === 'ongoing' && <TrasferPage />}
        {searchValue === 'profit' && 'Transfer Profit Page'} */}
      </Container>
    </>
  );
}

export default AllTrasfers;
