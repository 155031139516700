import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Tooltip, TableRow, TableCell, IconButton, Typography } from '@mui/material';

import useViewChoice from 'src/routes/hooks/use-view-choice';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import DepositModal from './depositModal';

export default function DepositTableRow({ row, selected, onDeleteRow, sort }) {
  const {
    date,
    amount,
    client_name,
    company_bank_account_name,
    bank_deposit_card_name,
    id,
    deposit_type,
  } = row;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const depositTypeValue = useViewChoice('v1.operations', 'DepositTypeChoices', deposit_type);

  function getDepositBackgroundColor(Value) {
    switch (Value) {
      case 'CASH':
        return 'success';
      case 'BANK_TRANSFER':
        return 'info';
      case 'DEPOSIT_CARD':
        return 'warning';
      default:
        return 'default';
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {(() => {
            const formattedDate = new Date(date);
            return `${formattedDate.getDate()}-${
              formattedDate.getMonth() + 1
            }-${formattedDate.getFullYear()}`;
          })()}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{client_name || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{bank_deposit_card_name || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{company_bank_account_name || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="body2" color="#637381">
            SR{' '}
            <Typography variant="body2" component="span" color="#212B36" fontWeight={600}>
              {amount}
            </Typography>
          </Typography>
        </TableCell>
        {!sort && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <Label
              color={getDepositBackgroundColor(depositTypeValue?.currentLabel)}
              sx={{
                // backgroundColor: getStatusBackgroundColor(statusValue?.currentLabel),
                // color: '#212B36',
                padding: '4px 8px',
                borderRadius: '4px',
                fontWeight: 'bold',
              }}
            >
              {depositTypeValue?.currentLabel}
            </Label>
          </TableCell>
        )}

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={handleOpenModal}
            sx={{
              backgroundColor: '#00000014',
              color: '#000000',
              borderRadius: '50px',
              px: 3,
              ':hover': { backgroundColor: '#00000014', color: '#000000' },
            }}
          >
            View
          </Button>

          <Tooltip title="Delete">
            <IconButton onClick={() => onDeleteRow(id)} sx={{ color: 'error.main' }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <DepositModal open={openModal} onClose={handleCloseModal} data={row} />
    </>
  );
}

DepositTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  id: PropTypes.any,
  sort: PropTypes.any,
};
