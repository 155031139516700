import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Paper,
  TableRow,
  TableCell,
  //   TextField,
  //   InputAdornment,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import noData from 'src/assets/other/companyBank.svg';
import { clearDepositDetails } from 'src/server/store/deposit';
import { deleteDeposit, getAllDeposits } from 'src/server/api/deposit';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import TableEmpty from 'src/components/table/table-empty';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import DepositTableRow from 'src/sections/Deposit/DepositTableRow';
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';
import DepositTableToolbar from 'src/sections/Deposit/depositTableToolbar';

// ----------------------------------------------------------------------

export default function DepositCardPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const location = useLocation();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 10 });

  const { loading, deposits } = useSelector((state) => ({
    loading: state.deposit.loading,
    deposits: state.deposit.deposits,
  }));

  const [searchText, setSearchText] = useState();
  const [sort, setSort] = useState('');
  const [selectedClient, setSelectedClient] = useState({ label: '', value: '' });
  const [selectedBankDepositCard, setSelectedBankDepositCard] = useState({ label: '', value: '' });
  const [selectedCompanyBankAccount, setSelectedCompanyBankAccount] = useState({
    label: '',
    value: '',
  });
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [data, setData] = useState('');
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [tableData, setTableData] = useState([]);
  const totalPage = deposits?.count;
  const identifier = 'deposit';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const content = {
    heading: 'Are you sure you want to delete this deposit? This action cannot be undone.',
  };

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'client', label: 'Client' },
    { id: 'bank_deposit_card', label: 'Bank Deposit Card' },
    { id: 'company_bank_account', label: 'Company Bank Account' },
    { id: 'amount', label: 'Amount' },
    { id: '', label: '' },
  ];

  if (!sort) {
    TABLE_HEAD.splice(5, 0, { id: 'deposit_type', label: 'Deposit Type' });
  }

  const handleDateChange = (field, value) => {
    const updatedDates = { ...dateRange, [field]: value };
    setDateRange(updatedDates);
    updateURLParams('date', updatedDates);
  };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText || '',
      client: selectedClient?.value || '',
      bankDepositCard: selectedBankDepositCard?.value || '',
      combanyBankAccount: selectedCompanyBankAccount?.value || '',
      dateFrom: dateRange.from,
      dateTo: dateRange.to,
      limit: storedRowsPerPage || table.rowsPerPage,
      depositType: sort,
      dispatch,
    };
    const timeoutId = setTimeout(() => {
      dispatch(getAllDeposits(credentials));
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [
    dateRange.from,
    dateRange.to,
    dispatch,
    searchText,
    selectedBankDepositCard,
    selectedClient,
    selectedCompanyBankAccount,
    table.page,
    table.rowsPerPage,
    storedRowsPerPage,
    sort,
  ]);

  useEffect(() => {
    setTableData(deposits?.results);
  }, [deposits]);

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const updateURLParams = (key, value, extraState = {}) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('p', 1);
    searchParams.set('l', table.rowsPerPage);
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      {
        state: {
          ...location.state,
          page: 0,
          ...extraState,
          [key]: value,
        },
      }
    );
  };

  useEffect(() => {
    setSearchText(location?.state?.search || '');
    table.setPage(location?.state?.page || 0);
    setSelectedClient(location?.state?.client || {});
    setSelectedBankDepositCard(location?.state?.bankDepositCard || {});
    setSelectedCompanyBankAccount(location?.state?.companyBankAccount || {});
    setDateRange(location?.state?.date || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location?.state?.search,
    location?.state?.page,
    location?.state?.client,
    location?.state?.bankDepositCard,
    location?.state?.companyBankAccount,
    location?.state?.date,
  ]);

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleDeleteRow = (depositId) => {
    setData(depositId);
    setOpenDeleteConfirmDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    table.setPage(0);
    updateURLParams('search', value);
  };

  const handlesetPage = () => {
    table.setPage(0);
  };
  const navigateRoute = (id) => {
    router.push(paths.dashboard.update_deposit_card(id));
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      depositId: data,
      handleCloseDeleteDialog,
    };
    dispatch(deleteDeposit(credentials));
  };

  const filterData = {
    selectedClient,
    setSelectedClient,
    selectedBankDepositCard,
    setSelectedBankDepositCard,
    selectedCompanyBankAccount,
    setSelectedCompanyBankAccount,
    searchText,
    handleSearch,
    dateRange,
    handleDateChange,
    handlesetPage,
    updateURLParams,
    sort,
    setSort,
  };

  const handleCreate = () => {
    dispatch(clearDepositDetails());
  };

  return (
    <>
      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={content}
        loading={loading}
        title="Deposit"
      />
      <CustomBreadcrumbs
        heading="Deposit"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },

          {
            name: 'Deposit',
          },
          {
            name: 'List',
          },
        ]}
        action={
          <Stack flexDirection="row" spacing={2}>
            <Link to={paths.dashboard.create_deposit} style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                onClick={handleCreate}
                startIcon={<Iconify icon="mingcute:add-line" />}
                sx={{ backgroundColor: '#C050B3', ':hover': { backgroundColor: '#C050B3' } }}
              >
                Deposit
              </Button>
            </Link>
          </Stack>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Card>
        <DepositTableToolbar filterData={filterData} />
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected?.length}
            rowCount={tableData?.length}
            onSelectAllRows={(checked) => {
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              );
            }}
          />
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
                onSort={table.onSort}
              />

              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <DepositTableRow
                      index={index}
                      key={row.id}
                      navigate={navigateRoute}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onEditRow={() => handleViewRow(row.id)}
                      onDeleteRow={handleDeleteRow}
                      loading={loading}
                      sort={sort}
                    />
                  ))}
                </TableBody>
              )}
              <TableEmpty
                img={noData}
                title="No deposits created yet"
                caption={'Click on the "+ New Deposit " button to add bank deposit .'}
                notFound={notFound}
              />
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalPage || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
          identifier={identifier}
        />
      </Card>
    </>
  );
}
